import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { appStateEvent, CURRENT_PROJECT_EVENT_INITIAL_STATE, EventState } from './event.state';
import * as eventActions from './event.actions';
import { AppState } from '../../store/models/app.state';
import { cloneDeep } from 'lodash-es';

const reducer = createReducer(
  appStateEvent.event,
  on(eventActions.dispatchedCreateUpdateEvent, (state, { dispatched }) => ({
    ...state,
    current: { ...state.current, dispatched: dispatched },
  })),
  on(eventActions.pendingCreateUpdateEvent, (state, { pending }) => ({
    ...state,
    current: { ...state.current, pending: pending },
  })),
  on(eventActions.successCreateUpdateEvent, (state, { success }) => ({
    ...state,
    current: { ...state.current, success: success },
  })),
  on(eventActions.updateEventField, (state, { value, field }) => ({
    ...state,
    current: {
      ...state.current,
      projectEvent: {
        ...state.current.projectEvent,
        event: {
          ...state.current.projectEvent.event,
          [field]: value,
        },
      },
    },
  })),
  on(eventActions.updateEventFields, (state, { event }) => ({
    ...state,
    current: {
      ...state.current,
      projectEvent: {
        ...state.current.projectEvent,
        event: {
          ...state.current.projectEvent.event,
          ...event,
        },
      },
    },
  })),
  on(eventActions.checkCurrentEventForPendingChanges, (state, { changes }) => ({
    ...state,
    current: { ...state.current, pendingChanges: changes },
  })),

  // project events
  on(eventActions.removeProjectEvent, (state, { projectEventId }) => {
    const index = state.listProjectEvents.findIndex(projectEvent => projectEvent.id === projectEventId);
    return index > -1
      ? {
          ...state,
          listProjectEvents: state.listProjectEvents.slice(0, index).concat(state.listProjectEvents.slice(index + 1)),
        }
      : state;
  }),
  on(eventActions.resetProjectEventList, state => ({ ...state, listProjectEvents: [] })),
  on(eventActions.changeCurrentProjectEvent, (state, { eventId }) => {
    const projectEvent = state.listProjectEvents.find(projectEvent => projectEvent.eventId === eventId);
    return projectEvent.eventId > -1
      ? {
          ...state,
          current: {
            ...state.current,
            projectEvent: cloneDeep(projectEvent),
            projectEventSnapshot: cloneDeep(projectEvent),
          },
        }
      : state;
  }),
  on(eventActions.revertEventEditorChanges, state => ({
    ...state,
    current: { ...state.current, projectEvent: cloneDeep(state.current.projectEventSnapshot) },
  })),
  on(eventActions.resetCurrentProjectEvent, state => {
    return {
      ...state,
      current: {
        ...state.current,
        projectEvent: {
          ...CURRENT_PROJECT_EVENT_INITIAL_STATE,
        },
        projectEventSnapshot: {
          ...CURRENT_PROJECT_EVENT_INITIAL_STATE,
        },
        eventsCommentsCounters: {},
      },
    };
  }),
  on(eventActions.populateCurrentProjectEvent, (state, { projectEvent }) => ({
    ...state,
    current: {
      ...state.current,
      projectEvent: projectEvent,
    },
  })),
  on(eventActions.populateCurrentProjectEventSnapshot, (state, { projectEvent }) => ({
    ...state,
    current: { ...state.current, projectEventSnapshot: cloneDeep(projectEvent) },
  })),
  on(eventActions.updateProjectEventList, (state, { projectEventList }) => ({
    ...state,
    listProjectEvents: projectEventList,
  })),
  on(eventActions.updateProjectEventInList, (state, { projectEvent }) => {
    const projectEventIndex = state.listProjectEvents.findIndex(d => d.id === projectEvent.id);
    return projectEventIndex > -1
      ? {
          ...state,
          listProjectEvents: [
            ...[...state.listProjectEvents.slice(0, projectEventIndex)],
            { ...projectEvent },
            ...[...state.listProjectEvents.slice(projectEventIndex + 1)],
          ],
        }
      : state;
  }),
  on(eventActions.updateEventNotificationsList, (state, { eventNotifications }) => ({
    ...state,
    listEventNotifications: eventNotifications,
  })),
  on(eventActions.resetEventNotificationsList, state => ({
    ...state,
    listEventNotifications: [],
  })),
  on(eventActions.populateProjectEventsCommentsCounter, (state, { counters }) => {
    const countObj = {};
    counters.forEach(counter => {
      countObj[counter.projectEventId] = counter.commentCount;
    });
    return {
      ...state,
      eventsCommentsCounters: countObj,
    };
  }),
);

export function eventReducer(state: EventState, action: Action): EventState {
  return reducer(state, action);
}

export const selectEvent = (state: AppState) => state.event;
export const selectEventList = createSelector(selectEvent, eventState => eventState.listProjectEvents);

export const selectEventsCommentsCounter = createSelector(selectEvent, eventState => eventState.eventsCommentsCounters);
