import { Component, inject, Inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEventComment } from './event-comment.interface';
import { SharedModule } from '../../shared/shared.module';
import { EntityCommentsBaseComponent } from '../../shared/entity-comments/entity-comment-base/entity-component-base.component';
import { IEntityComment } from '../../shared/models/entity-comments.interface';
import { ProjectService } from '../../project/project.service';
import { take, takeUntil } from 'rxjs/operators';
import { errorPopup, messagePopup } from '../../store/actions/ui.actions';

@Component({
  selector: 'phar-event-comment-dialog',
  templateUrl: 'event-comment.dialog.component.html',
  styleUrl: 'event-comment.dialog.component.scss',
  standalone: true,
  imports: [SharedModule],
})
export class EventCommentDialogComponent extends EntityCommentsBaseComponent implements OnInit {
  declare comments: IEventComment[];
  projectService: ProjectService = inject(ProjectService);
  isLoading = signal(false);
  private refreshCounters = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      projectEventId: number;
    },
    public dialogRef: MatDialogRef<EventCommentDialogComponent>,
  ) {
    super();
  }

  ngOnInit() {
    this.loadComments();
    this.handleBackdropClick();
  }

  private loadComments(): void {
    const { projectEventId } = this.data;
    if (!projectEventId) {
      return;
    }

    this.isLoading.set(true);
    this.projectService
      .getProjectEventComments(projectEventId)
      .pipe(take(1))
      .subscribe({
        next: comments => {
          this.comments = comments;
          this.isLoading.set(false);
        },
        error: () => {
          this.isLoading.set(false);
          this.store.dispatch(errorPopup({ error: 'Failed to load comments' }));
        },
      });
  }

  handleDeleteComment($event: IEntityComment) {
    this.projectService
      .deleteProjectEventComment($event.id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.store.dispatch(messagePopup({ message: 'Comment delete' }));
          this.refreshCounters = true;
          this.loadComments();
        },
        error: () => {
          this.store.dispatch(errorPopup({ error: 'Failed to delete comment' }));
        },
      });
  }

  handleResolveComment($event: IEntityComment) {
    this.projectService
      .resolveProjectEventComment($event.id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.store.dispatch(messagePopup({ message: 'Comment resolved' }));
          this.loadComments();
        },
        error: () => {
          this.store.dispatch(errorPopup({ error: 'Failed to resolve comment' }));
        },
      });
  }

  handleEditComment($event: IEntityComment) {
    this.projectService
      .updateProjectEventComment($event as IEventComment)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.store.dispatch(messagePopup({ message: 'Comment updated' }));
          this.closeDialog();
        },
        error: () => {
          this.store.dispatch(errorPopup({ error: 'Failed to update comment' }));
        },
      });
  }

  handleCreateComment($event: IEntityComment) {
    const payload: IEventComment = {
      ...$event,
      projectEventId: this.data.projectEventId,
    };
    this.projectService
      .createProjectEventComment(payload)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.store.dispatch(messagePopup({ message: 'Comment created' }));
          this.refreshCounters = true;
          this.closeDialog();
        },
        error: () => {
          this.store.dispatch(errorPopup({ error: 'Failed to create comment' }));
        },
      });
  }

  closeDialog(): void {
    this.dialogRef.close(this.refreshCounters);
  }

  private handleBackdropClick(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.closeDialog();
      });
  }
}
